.app-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 12.5%;
  min-width: 220px; /* Add a minimum width */
  overflow: auto;
}

.main-content {
  flex-grow: 1; /* Adjust this to take up the remaining width */
  overflow: auto;
}

.top-navbar {
  height: 10%;
  min-height: 80px;
  max-height: 80px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.main-container {
  padding: 16px;
  background-color: #fafafa;
}

.sign-up-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: none !important;
}
